import React from "react";

const Book = () => (
  <>
    <div className="card">
      <div className="card-image">
        <figure className="image is-1by1">
          <img
            src="./static/img/the-three-cover.png"
            alt="The Three Book cover"
          />
        </figure>
      </div>
      <hr />
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">Th3 Simple Questions</p>
          </div>
        </div>
        <br />
        <div className="content">
          <p className="subtitle is-4">GOODREADS REVIEW</p>
          <p className="subtitle is-5">
            <em>5 of 5 stars</em>
          </p>
          <time>
            <em>7/4/15 2:35am</em>
          </time>
          <p>
            <em>Th3 Simple Questions - Slice Open Everyday Life</em> by Mr. Jean
            W. Yeager. Honestly I have been in a slump for awhile when it comes
            to reading books until I got my hands on this one... let's just say
            this amazing book found me and I am ecstatic that it did. The style
            of short essays or 'slices' each with the "Who am I", "Why am I
            here", and "What do I want", format took me by surprise as it was an
            energizing way to read and process thought provoking concepts with
            each 'slice' only being a few pages long and the analogies were
            artfully presented as real life experiences to which most anyone can
            relate.
          </p>
          <p>
            My favorite slice was Dandelion Wine: Alchemical Batting Practice as
            I fully immersed myself into this 'slice' with my own imagery which
            I could completely relate to as it left me with the warmth of
            sunshine still in my mind. All of the 'slices' were masterfully
            crafted without needing page after page of extra words to get the
            author's intended thought provoking points across.
          </p>
          <p>
            Mr. Jean - Willie Mays would have been impressed as you hit this one
            'out of the park' worth reading more than once...... 5 Stars
          </p>
          <p>Many Thanks :={"}"}</p>
          <p>Mark Krausman</p>
          <hr />
          <p className="has-text-centered">
            <a
              className="button is-success"
              href="https://344944.e-junkie.com/product/1643562/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="mdi mdi-cart-outline"></i>
              </span>
              <span>$3.99 eBook</span>
            </a>
          </p>
          <p className="has-text-centered">
            <a
              className="button is-success"
              href="https://www.amazon.com/Th3-Simple-Questions-Slice-Everyday-ebook/dp/B0793P18M8/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="mdi mdi-cart-outline"></i>
              </span>
              <span>Amazon</span>
            </a>
          </p>
          <p className="has-text-centered">
            <a
              className="button is-success"
              href="https://www.smashwords.com/books/view/529903"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="mdi mdi-cart-outline"></i>
              </span>
              <span>Smashwords</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </>
);
export default Book;
