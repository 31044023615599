import React from "react";

const Hartman = () => (
  <>
    <div className="card">
      <div className="card-image">
        <figure className="image is-3by4">
          <img src="./static/img/hartman-logo-3x4.png" alt="Hartman Center" />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">Hartman Center</p>
          </div>
        </div>
        <div className="content">
          <p>
            The most comprehensive archive of U.S. advertising, sales and
            marketing, The Hartman Center at Duke University Libraries holds a
            collection of more than 3 million items.
          </p>
          <p>
            Jean W. Yeager’s collected works were accepted into this collection
            in 2014.
          </p>
          <p>Visit the collection to see the detailed listing.</p>
        </div>
        <nav className="level">
          <div className="level-item has-text-centered">
            <a
              className="button is-info"
              href="https://library.duke.edu/rubenstein/findingaids/yeagerjean/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="mdi mdi-link-variant"></i>
              </span>
              <span>Learn More</span>
            </a>
          </div>
        </nav>
      </div>
    </div>
  </>
);

export default Hartman;
