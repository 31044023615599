import React from "react";

class DocLink extends React.Component {
  render() {
    return (
      <>
        <li>
          <a
            href={this.props.docPath}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon has-text-danger">
              <i className="mdi mdi-file-pdf"></i>
            </span>{" "}
            {this.props.title}
          </a>
        </li>
      </>
    );
  }
}

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: [
        {
          docPath: "./static/docs/Resume-JWYeager.pdf",
          title: "Resume",
        },
        {
          docPath: "./static/docs/Corporate-Communication.pdf",
          title: "Corporate Communications List",
        },
        {
          docPath: "./static/docs/Radio-Television-Film.pdf",
          title: "Theatrical, Screenplays, Television",
        },
        {
          docPath: "./static/docs/Current-Work.pdf",
          title: "Theatrical Produced",
        },
        {
          docPath: "./static/docs/Theatrical-Unproduced.pdf",
          title: "Theatrical Unproduced",
        },
        {
          docPath: "./static/docs/Nontheatrical-Literary.pdf",
          title: "Non-Theatrical Literary",
        },
        {
          docPath: "./static/docs/Professional-Practice-2018.pdf",
          title: "Professional Practice",
        },
        {
          docPath: "./static/docs/Jean-Yeager-Writing-Biography.pdf",
          title: "Jean Yeager Writing Biography",
        },
      ],
    };
  }
  render() {
    const docsList = this.state.docs.map((doc, idx) => (
      <DocLink key={idx} title={doc.title} docPath={doc.docPath}></DocLink>
    ));
    return (
      <>
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">Documents</p>
          </header>
          <div className="card-content">
            <div className="content">
              <ul className="mt-0" style={{ listStyleType: "none" }}>{docsList}</ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Documents;
