import React from 'react';
import Box from '../components/AudioBox'

class AudioCabells extends React.Component {
  constructor(props){
    super(props);
    this.state = { audios:[
      {
        author:'(Daniel P. McCurdy, Writer)',
        audioPath: './static/audio/cabells-10-bill.mp3',
        forCompany: 'for Cabells Dairy',
        imgPath: './static/img/cabells-dairy-sq.jpg',
        title: '$10 Bill'
      },
      {
        author:'(Jean Yeager, Writer)',
        audioPath: './static/audio/cabells-jumper.mp3',
        forCompany: 'for Cabells Dairy',
        imgPath: './static/img/cabells-dairy-sq.jpg',
        title: 'Jumper Cabells'
      },
      {
        author:'(Bob Stanford, Writer)',
        audioPath: './static/audio/cabells-test-2.mp3',
        forCompany: 'for Cabells Dairy',
        imgPath: './static/img/cabells-dairy-sq.jpg',
        title: 'Man on the Street'
      }
    ]}
  }

  render() {
    const audioList = this.state.audios.map((audio, idx)=>(
      <Box
      key={idx}
      author={audio.author}
      audioPath={audio.audioPath}
      forCompany={audio.forCompany}
      imgPath={audio.imgPath}
      title={audio.title} >
      </Box>
    ))
    return (
      <>
      {audioList}
      </>
    );
  }
}

export default AudioCabells
