import React from 'react';
import Box from '../components/AudioBox'

class AudioBalloon extends React.Component {
  constructor(props){
    super(props);
    this.state = { audios:[
      {
        author:'Mr. Balloon Series',
        audioPath: './static/audio/mr-balloon-coffee-and-danish.mp3',
        forCompany: 'for 7-Eleven',
        imgPath: './static/img/mr-balloon-sq_sm.jpeg',
        title: 'Coffee and Danish'
      },
      {
        author:'Mr. Balloon Series',
        audioPath: './static/audio/mr-balloon-sing-along.mp3',
        forCompany: 'for 7-Eleven',
        imgPath: './static/img/mr-balloon-sq_sm.jpeg',
        title: 'Sing Along'
      },
      {
        author:'Mr. Balloon Series',
        audioPath: './static/audio/mr-balloon-hot-chocolate-laryngitis.mp3',
        forCompany: 'for 7-Eleven',
        imgPath: './static/img/mr-balloon-sq_sm.jpeg',
        title: 'Hot Chocolate Laryngitis'
      }
    ]}
  }

  render() {
    const audioList = this.state.audios.map((audio, idx)=>(
      <Box
      key={idx}
      author={audio.author}
      audioPath={audio.audioPath}
      forCompany={audio.forCompany}
      imgPath={audio.imgPath}
      title={audio.title} >
      </Box>
    ))
    return (
      <>
      {audioList}
      </>
    );
  }
}

export default AudioBalloon
