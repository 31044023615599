import React from "react";
import ContactBadges from "./ContactBadges";
import ContactIcons from "./ContactIcons";
import ContactLinks from "./ContactLinks";

const Profile = () => (
  <div className="card">
    <div className="card-image">
      <figure className="image is-1by1">
        <img src="./static/img/jean-med.jpg" alt="Jean Yeager" />
      </figure>
    </div>
    <div className="card-content">
      <p className="title is-4">Jean Yeager</p>
      <p className="subtitle is-6">The Human Story as Lived and Told</p>
      <p>
        38 Kendall Ave <br />
        Rutland, VT 05701 <br />
        Home: 802-855-8877
      </p>
      <ContactLinks />
      <ContactBadges />
      <ContactIcons />
    </div>
  </div>
);

export default Profile;
