import React from "react";

const AACert2020 = () => (
  <div className="card">
    <div className="card-image">
      <figure className="image is-4by3">
        <img
          src="./static/img/2020-aa-first-prize-cert.jpg"
          alt="2020 Ageless Authors First Prize Certificate"
        />
      </figure>
    </div>
  </div>
);

export default AACert2020;
