import React from "react";

const ContactIcons = () => (
  <nav className="level">
    <div className="level-item has-text-centered">
      <a
        href="https://twitter.com/ThreeSimpleQues"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon has-text-twitter">
          <span className="mdi mdi-twitter mdi-36px"></span>
        </span>
      </a>
    </div>
    <div className="level-item has-text-centered">
      <a
        href="https://www.linkedin.com/in/jeanyeager2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon has-text-info">
          <span className="mdi mdi-linkedin-box mdi-36px"></span>
        </span>
      </a>
    </div>
    <div className="level-item has-text-centered">
      <a
        href="http://threesimplequestions.blogspot.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon has-text-blogger">
          <span className="mdi mdi-blogger mdi-36px"></span>
        </span>
      </a>
    </div>
    <div className="level-item has-text-centered">
      <a href="mailto:jwyeager2@gmail.com">
        <span className="icon has-text-mailto">
          <span className="mdi mdi-email mdi-36px"></span>
        </span>
      </a>
    </div>
    <div className="level-item has-text-centered">
      <a
        href="https://newenglandfilm.com/members/jwyeager/films"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon has-text-success">
          <span className="mdi mdi-script mdi-36px"></span>
        </span>
      </a>
    </div>
  </nav>
);
export default ContactIcons;
