import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Works = () => (
  <>
    <Navbar></Navbar>
    <section className="section">
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="content">
            <h2>Current Work</h2>
            <p>
              Currently I am offering two feature-length scripts online at
              inktip.com and New England Film.com
            </p>
            <h3>A-Wire</h3>
            <p>
              <em>GENRE: HISTORICAL DRAMATIC</em>
            </p>
            <p>
              <em>LOGLINE:</em> It's a story we already know - the John Kennedy
              assassination, November 22,1963. But, this is the behind-
              the-scenes story of the Wire Service writers, editors and teletype
              operators in Dallas who sorted through carnage, conflicting
              questions, and frustrating technology to get the story, get it
              right, and get it on the A-Wire. The ending touches the more than
              1.5 million messages of condolence received by Jackie following
              the assassination.
            </p>
            <h3>VELCRO PFC</h3>
            <h4>Alternate Title: How Santa Claus Came To Whisky Alpha Romeo</h4>
            <p>
              <em>GENRE: ACTION / ADVENTURE</em>
            </p>
            <p>
              <em>LOGLINE:</em> When DICK BULLEN, a “Velcro PFC” and former “bad
              boy” motocross star decides to ride through combat on Christmas
              Eve to be Santa for a young Muslim boy; he and FAWZIA AKBAR, a
              “Lipstick Freedom Fighter”, take on The Brotherhood, a
              Taliban-like enemy in live-fire motocross combat.
            </p>
            <h2>THEATRICAL - PRODUCTIONS</h2>
            <ul>
              <li>
                2013 - Lucky Penny Productions and Napa Valley Theatre, Napa, CA
                selected "How Santa Clause Came To Whisky Alpha Romeo" (10:00)
                as part of their "8 Tens at Christmas" series
              </li>
              <li>
                2013 - TMPW, New York City, staged reading, “Pop Goes The MAD
                Man” (10:00)
              </li>
              <li>
                2013 Theatre One, Middleboro, MA staged reading, “Dante In Jiffy
                Lube” (10:00)
              </li>
              <li>
                2012 Nor'Eastern Play Writing Contest, Semifinalist, “The
                Substance of Love” - One Act
              </li>
              <li>
                2011 Nor'Eastern Play Writing Contest, Finalist, “Dante In Jiffy
                Lube” - One Act
              </li>
              <li>
                2009 “Clothes Line”; “Best of the Rest”, Actor's Theatre, Santa
                Cruz, CA; Liana Moraru, Director. (Staged reading.)
              </li>
              <li>
                2008 “Prisoner #101067 Public Speaking Report: The Life And
                Times of Tupac Shakur And Excerpts From The Prince by Niccolo
                Machiavelli”, Staged reading by Resilience of the Spirit: Human
                Rights Festival (July) 2008. 6th @ Penn Theatre, San Diego, CA
              </li>
              <li>
                “Peace Child”, 1988, Commissioned by Unity Church of Dallas, TX.
                Full length musical production performed by more than 60
                children and adults.
              </li>
              <li>
                “A Day In The Life Of Bennett Glazier”, 1985, Commissioned by
                Roger Staubach, former Dallas Cowboys Quarterback, Dallas, TX.
                One-act comedy performed at a private birthday party attended by
                some 100 close friends.
              </li>
              <li>
                “Thanksgiving: The American Spirit”, 1984, Randy Tallman
                Productions. One-act historical drama performed at Thanksgiving
                Square, Dallas, TX for the National Day of Prayer and
                Thanksgiving during the Republican National Convention.
              </li>
            </ul>
            <h2>TELEVISION / PRODUCED</h2>
            “The Texas 150th Birthday Celebration”, 1985, treatment commissioned
            by Cormac Communications / Texas National Television Productions.
            Produced by CBS, this 3-hour television special was hosted by Willie
            Nelson and others.
            <h2>SCREENPLAYS / OPTIONED</h2>
            <ul>
              <li>
                1978 -“Night Ride”, © Copyright 1978. Optioned to Crescendo
                Cinema III, Fort Worth, TX. Feature length.
              </li>
            </ul>
            <h2>CORPORATE FILMS / SHOWS</h2>
            <p>(Selected from more than 100 productions)</p>
            <ul>
              <li>
                2007 - Word Of Faith International Christian Center, Detroit,
                MI. 10:00 second web musical intro. lyrics for Media Arts
                Productions, Detroit, MI
              </li>
              <li>
                2005 - “Tech Know”, Media Arts Productions, Detroit, MI. Comedy
                for University of Michigan Technology Transfer Agency, stage
                show / video shoot
              </li>
              <li>
                2005 - “N.E.W. Center 10th Anniversary”, Media Arts Productions,
                Detroit, MI. Non-Profit Enterprise At Work 10th Anniversary
                highlight show
              </li>
              <li>
                1991 - “The Ark In The Park”, Theatre Arts Productions,
                Birmingham, UK. London Zoological Society multi-media production
              </li>
              <li>
                1989 - Represented by Lexicon Creative Services Ltd., London,
                England. Scripts for British Telecom, Social Services and other
                enterprises
              </li>
              <li>
                1985 - “Coca-Cola Centennial Bottlers' Meeting”, Produced by
                Corporate Magic, Dallas, TX. Four-hour extravaganza staged in
                the Omni Centre in Atlanta, GA and included live song /dance
                starring the Pointer Sisters and film / video / slide
                segments.featuring stars from MGM (owned by Coke at that time).
                I led a team of 3 writers who wrote for more than one year on
                this show
              </li>
              <li>
                1984 - “National Association Of Broadcasters”, Produced by
                Corporate Magic, Dallas, TX. Three hour live performance
                including comedy, lyrics and film / video / slide elements. Then
                President Ronald Regan delivered a video segment which I wrote
              </li>
              <li>
                1983 - “Southland Corporation Annual Sales Meeting”, 1983. Bill
                Cosby, host. Three hour live performance including comedy,
                lyrics and film / video / slide elements
              </li>
            </ul>
            <h2>FEATURE LENGTH SCREENPLAYS AVAILABLE</h2>
            <ul>
              <li>
                2013 - “A-Wire”. Feature. Historical / Drama. Registered WGAe
              </li>
              <li>
                2013 - “Velcro PFC”. Feature / Action Adventure. Registered WGAe
              </li>
              <li>
                2013 - “How Santa Claus Came To Whisky-Alpha-Romeo”. Feature /
                Action Adventure. Registered WGAe
              </li>
              <li>
                2005 - “How Santa Claus Came To Simpson's Bar”, © Copyright
                2005, Registered WGAe, # I29276. Feature length
              </li>
              <li>
                1996 - “How Santa Claus Came To Simpson's Bar”, © Copyright
                1996, Registered WGAe, #102829-00. Feature length
              </li>
            </ul>
            <h2>SCREENPLAYS / OPTIONED</h2>
            <ul>
              <li>
                1978 -“Night Ride”, © Copyright 1978. Optioned to Crescendo
                Cinema III, Fort Worth, TX. Feature length
              </li>
            </ul>
            <h2>TELEVISION / PRODUCED</h2>
            <p>
              “The Texas 150th Birthday Celebration”, 1985, treatment
              commissioned by Cormac Communications / Texas National Television
              Productions. Produced by CBS, this 3-hour television special was
              hosted by Willie Nelson and others.
            </p>
            <h2>TELEVISION CONCEPTS AVAILABLE</h2>
            <ul>
              <li>
                2005 - “The Homeless Millionaire“, treatment commissioned by
                Media Arts, Detroit, MI. Registered WGA west #1051065. A reality
                tv concept for a pitch to John Feist, Co-Executive Producer, NBC
                Summer Series. <em>Unproduced</em>
              </li>
              <li>
                2002- “100 Stories”, concept commissioned by Media Arts,
                Detroit, MI for Word of Faith International Christian Center. A
                half-hour television program with standard sitcom timing and
                sequencing for an audience of 6-10 year olds.{" "}
                <em>Unproduced</em>
              </li>
            </ul>
            <h2>ADVERTISING AWARDS</h2>
            <ul>
              <li>
                1985 - ADDY AWARDS, Finalist :60 radio commercial comedy /
                national
              </li>
              <li>
                1983 - CLIO AWARDS, Finalist :60 radio commercial comedy /
                national
              </li>
              <li>
                1983 - ART DIRECTORS OF NEW YORK {"("}ANDY{")"} AWARD, Finalist
                :60 radio commercial comedy national
              </li>
              <li>
                1981 - INTERNATIONAL BROADCASTING AWARD (IBA), Winner :60 radio
                commercial comedy
              </li>
              <li>
                1979 - INTERNATIONAL BROADCASTING AWARDS (IBA), Winner :60 radio
                commercial comedy.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </>
);
export default Works;
