import React from "react";

class AudioBox extends React.Component {
  render() {
    return (
      <>
        <div className="box">
          <div className="media">
            <div className="media-left">
              <figure className="image is-128x128">
                <img src={this.props.imgPath} alt={this.props.title} />
              </figure>
            </div>
            <article className="media-content">
              <div className="content">
                <p>
                  <strong>{this.props.title}</strong>
                  <br />
                  <small>{this.props.forCompany}</small>
                  <br />
                </p>
                {this.props.author}
              </div>
            </article>
          </div>
          <div className="content has-text-centered">
            <audio controls>
              <source src={this.props.audioPath} type="audio/mpeg" />
              Your browser does not support the audio tag.
            </audio>
          </div>
        </div>
      </>
    );
  }
}

export default AudioBox;
