import React from "react";
import ContactBadges from "../parts/ContactBadges";
// import ContactIcons from '../parts/ContactIcons';
import ContactLinks from "../parts/ContactLinks";

const Footer = () => (
  <footer className={"footer"} style={{ backgroundColor: "#FFF" }}>
    <div className="container">
      <div className={"columns"}>
        <div className={"column is-one-third"}>
          <ContactBadges />
        </div>
        <div className={"column"}>
          <ContactLinks />
        </div>
        {/* <div className={'column'}>
          <ContactIcons />
          <hr/>
          <div className="content has-text-centered has-text-grey-light">
            <p>A <a href="https://yeager.works" className={'has-text-grey'} target="_blank" rel="noopener noreferrer">Yeager Works</a> Project</p>
          </div>
        </div> */}
      </div>
    </div>
  </footer>
);
export default Footer;
