import React from "react";

const BookKite = () => {
  return (
    <>
      <div className="card">
        <div className="card-image">
          <figure className="image is-1by1">
            <img
              src="./static/img/kite-of-your-genius.jpg"
              alt="Kite Of Your Genius cover"
            />
          </figure>
          <hr />
        </div>
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <p className="title is-4">Kite Of Your Genius</p>
            </div>
          </div>
          <div className="content">
            <p className="has-text-centered">
              <a
                className="button is-success"
                href="https://344944.e-junkie.com/product/1643566/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <i className="mdi mdi-cart-outline"></i>
                </span>
                <span>$3.99 eBook</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default BookKite;
