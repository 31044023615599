import React from "react";

const ContactLinks = () => (
  <>
    <strong>INTERNET REFERENCES</strong>
    <p>
      Professional background site:{" "}
      <a
        href="http://www.th3simplequestions.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        http://www.th3simplequestions.com
      </a>
    </p>
    <p>
      Linkedin:{" "}
      <a
        href="https://www.linkedin.com/in/jeanyeager2"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.linkedin.com/in/jeanyeager2/
      </a>
    </p>
    <p>
      BLOG - THREE SIMPLE QUESTIONS:{" "}
      <a
        href="http://threesimplequestions.blogspot.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        http://www.threesimplequestions.blogspot.com
      </a>
    </p>
    <p>
      BLOG - TIMELINE WONKS:{" "}
      <a
        href="https://timelinewonk.blogspot.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://timelinewonk.blogspot.com/
      </a>
    </p>
    <p>
      Twitter:{" "}
      <a
        href="https://twitter.com/ThreeSimpleQues"
        target="_blank"
        rel="noopener noreferrer"
      >
        @ThreeSimpleQues
      </a>
    </p>
  </>
);
export default ContactLinks;
