import React from 'react';
import Box from '../components/AudioBox'

class Audio711 extends React.Component {
  constructor(props){
    super(props);
    this.state = { audios: [
      {
        author:'Jean Yeager, Writer',
        audioPath: './static/audio/lucky-phone-call.mp3',
        forCompany: 'for 7-Eleven',
        imgPath: './static/img/lucky-phone-call-sq_sm.jpeg',
        title: 'Lucky Phone Call'
      },
      {
        author:'Not listed',
        audioPath: './static/audio/lion-manure.mp3',
        forCompany: 'for 7-Eleven',
        imgPath: './static/img/lion-sq.jpg',
        title: 'Lion Manure'
      },
      {
        author:'Don Coburn, Writer',
        audioPath: './static/audio/7-11-beer.mp3',
        forCompany: 'for 7-Eleven',
        imgPath: './static/img/711-Beer-sq.jpg',
        title: '7-Eleven Beer'
      },
      {
        author:'Bob Peck, Writer',
        audioPath: './static/audio/inventor-of-ice.mp3',
        forCompany: 'for 7-Eleven',
        imgPath: './static/img/eskimo-sq.jpg',
        title: 'Inventor of Ice'
      }
    ]}
  }

  render() {
    const audioList = this.state.audios.map((audio, idx)=>(
      <Box
      key={idx}
      author={audio.author}
      audioPath={audio.audioPath}
      forCompany={audio.forCompany}
      imgPath={audio.imgPath}
      title={audio.title} >
      </Box>
    ))
    return (
      <>
      {audioList}
      </>
    );
  }
}

export default Audio711
