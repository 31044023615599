import React from "react";

function BookFireBorne() {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-3by4">
          <img
            src="./static/img/cover_fireborne_3x4.jpeg"
            alt="Fire Borne, Anthroposophy in America book cover."
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="content has-text-centered">
          <h3>Fire Borne</h3>
          <h4>
            <em>Anthroposophy in America</em>
          </h4>
        </div>
        <div className="content">
          <p>
            FIRE BORNE is a richly illustrated, historical timeline of the work
            which Anthroposophy in America fostered during its first century.
          </p>
          <p>
            Published by ANTHROPOSOPHICAL PUBLICATIONS in an 8 ½” X 11” format,
            FIRE BORNE is available in paperback, eBook and Hardback editions
            from Ingram Spark Productions{" "}
            <em>
              (now #8 in Ingram Spark national sales in the category of
              history!)
            </em>
            .
          </p>
          <p>
            Written by JEAN W. YEAGER -Administrative Director of
            Anthroposophical Society in America (1996-2006). Vice President of
            the Board of the Biodynamic Association (1997-2002). Board Chair,
            Rudolf Steiner College (2005-2008) Award Winning Playwright,
            Essayist and Fiction Writer.
          </p>
          <em>
            Anthroposophy is a path of knowledge, personal growth, and service
            inspired by the work of Rudolf Steiner. It has created several
            "daughter movements" including Waldorf Education, Biodynamics, RSF
            Social Finance, Arts, Camphill Villages, Medical/Therapeutic
            Centers, Publishers, and more.
          </em>
        </div>
        <hr />
        <div className="content">
          <p className="has-text-centered">
            <a
              className="button is-success"
              href="https://344944.e-junkie.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="mdi mdi-cart-outline"></i>
              </span>
              <span>$25 softcover</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
export default BookFireBorne;
