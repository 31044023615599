import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => (
  <nav
    className="navbar has-shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <div className="navbar-brand">
      <Link to="/" className="navbar-item">
        <h3 className="subtitle">The-Three.com</h3>
      </Link>
    </div>
    <div className="navbar-menu">
      <div className="navbar-start">
        <Link to="/works" className="navbar-item">
          Works
        </Link>
        <Link to="/kudos" className="navbar-item">
          Kudos
        </Link>
      </div>
    </div>
  </nav>
);
export default NavBar;
