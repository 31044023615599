import React from "react";

const MoralicAcid = () => (
  <>
    <div className="card">
      <div className="card-content">
        <div className="content">
          <div className="is-flex is-justify-content-space-evenly mb-4">
            <img
              style={{ width: "5rem", height: "6.5rem" }}
              src="./static/img/moralic-acid-cover.jpg"
              alt="Moralic Acid book cover."
            />
            <div>
              <p className="title is-4 has-text-centered mb-1">
                <strong>MORALIC ACID</strong>
              </p>
              <p className="title is-5 has-text-centered mb-1">
                <strong>Satire &amp; Truth</strong>
              </p>
              <p className="title is-4 has-text-centered">
                <strong>MORALIC CATALYST</strong>
              </p>
            </div>
          </div>
          <h4>
            <em>2015-2016 Political bLog Posts</em>
          </h4>
          <p className="subtitle is-6">
            A collection of biting political satire in small doses like moralic
            acid, and truth like a moralic catalyst for change. These drew
            thousands of hits as blog posts.{" "}
            <em>
              &quot;I Am The Tongue Of The Liar&quot;, &quot;Dick Cheney&#39;s
              Brain Wants To Run For President,&quot; &quot;Trump-O-Rico - The
              U.S.&#39;s &#39;Mini Me&#39;&quot;, &quot;Lighting Alt
              Farts&quot;, &quot;Jam Tomorrow Says The White Queen&quot;,
              &quot;Authoritarian Eyes&quot;, &quot;Niccolo Machiavelli and
              Resurrection Forces&quot;, &quot;B&#39;rer Sanders and The Tar
              (Sands) Baby&quot;
            </em>{" "}
            and more!
          </p>
        </div>
        <hr />
        <nav className="level">
          <div className="level-item has-text-centered">
            <a
              className="button is-success"
              href="https://344944.e-junkie.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="mdi mdi-cart-outline"></i>
              </span>
              <span>$3.99 eBook</span>
            </a>
          </div>
        </nav>
      </div>
    </div>
  </>
);

export default MoralicAcid;
