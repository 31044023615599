import React from "react";

const BookAAuth = () => (
  <>
    <div className="card">
      <div className="card-image">
        <figure className="image is-5by4">
          <img
            src="./static/img/2017-aa-first-prize-cert.jpg"
            alt="2017 Ageless Authors First Prize Certificate"
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-3">
              <em>Winner!</em>
            </p>
            <p className="subtitle is-4">Essay Category</p>
            <p className="subtitle is-6">'Kite Of Our Genius - Sail On!'</p>
          </div>
        </div>
        {/* <br />
      <div className='content'>
        <nav className='level'>
          <div className='level-item has-text-centered'>
            <a className='button is-info' href='https://www.agelessauthors.com/current-contests/2016-ageless-authors-writing-contest-winners/' target='_blank' rel='noopener noreferrer'>
              <span className='icon'>
                <i className='mdi mdi-link-variant'></i>
              </span>
              <span>Read More</span>
            </a>
          </div>
        </nav>
      </div> */}
      </div>
    </div>
  </>
);

export default BookAAuth;
