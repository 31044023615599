import React from "react";

const PlayTWill17 = () => (
  <>
    <div className="card">
      <div className="card-image">
        <figure className="image is-3by1">
          <img
            src="./static/img/TWilliams2017.jpg"
            alt="Tennessee Williams One Act Playwriting"
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="content">
          <p className="title is-3">2017 Finalist</p>
          <h3 className="subtitle is-6">
            Tennessee Williams One Act Playwriting Contest
          </h3>
          <h3 className="subtitle is-6">
            “HOW SANTA CLAUS CAME TO ZONE WHISKY-ALPHA-ROMEO (WAR)”
          </h3>
        </div>
      </div>
    </div>
  </>
);

export default PlayTWill17;
