import React from "react";

const PlayDorset18 = () => (
  <>
    <div className="card">
      <div className="card-content">
        <div className="content">
          <p className="title is-3 has-text-centered">
            <strong>"CLOTHESLINE"</strong>
          </p>
          <p className="subtitle is-6">
            <strong>LOGLINE:</strong> <em>Clothesline</em> – sounds simple,
            doesn’t it? But what a wife has in mind when she says “clothesline”
            and what a husband has in mind may be the difference between
            sleeping in marital bliss and sleeping in the doghouse.
          </p>
          <img
            src="./static/img/clothesline-couple.jpeg"
            alt="Dorest play actors"
          />
          <p className="subtitle is-6 has-text-centered">
            <strong>
              “Honey, may I show you what a real clothesline looks like?”
            </strong>
          </p>
          <p className="subtitle is-6">
            Tom and Jude Norton (of Manchester, VT), a happily married couple in
            real life, play the scrappy couple in <em>Clothesline</em>.
          </p>
          <hr />
          <p className="title is-3">Wrote and Directed</p>
          <h3 className="subtitle is-6">
            2018 Dorset, VT Players 15th Annual One-Act Play Competition
          </h3>
        </div>
        {/* <nav className='level'>
        <div className='level-item has-text-centered'>
          <a className='button is-info' href='./static/docs/ClothesLine-3.pdf' target='_blank' rel='noopener noreferrer'>
            <span className='icon'>
              <i className='mdi mdi-file-pdf'></i>
            </span>
            <span>Download</span>
          </a>
        </div>
      </nav> */}
      </div>
    </div>
  </>
);

export default PlayDorset18;
