import React from "react";

const TwfestOneAct = () => {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-3by4">
          <img
            src="./static/img/twfest_one-act_winners.jpg"
            alt="Tennessee Williams One-Act Finalists"
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="content has-text-centered">
          <h3>2022 TWFest Announcement</h3>
        </div>
      </div>
    </div>
  );
};
export default TwfestOneAct;
