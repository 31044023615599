import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AACert2020 from "../parts/AACert2020";
import Audio711 from "../parts/Audio711";
import AudioBalloon from "../parts/AudioBalloon";
import AudioCabells from "../parts/AudioCabells";
import Book from "../parts/Book";
import BookAAuth from "../parts/BookAAuth";
import BookKite from "../parts/BookKite";
// import ClinicPhases from '../parts/ClinicPhases';
import Documents from "../parts/Documents";
import Hartman from "../parts/Hartman";
import PlayDorset18 from "../parts/PlayDorset18";
import PlayTWill17 from "../parts/PlayTWill17";
// import PhoenixBooks from '../parts/PhoenixBooks';
import Profile from "../parts/Profile";
import LasVegasFinalist from "../parts/LasVegasFinalist";
import TimelineWonk from "../parts/TimelineWonk";
import TwfestOneAct from "../parts/TwfestOneAct";
import BookFireBorne from "../parts/BookFireBorne";
import MoralicAcid from "../parts/MoralicAcid";

function HomePage() {
  return (
    <>
      <Navbar />
      <div className="section" style={{ backgroundColor: "#D5E9FF" }}>
        <div className="columns">
          <div className="column">
            <Profile />
            <br />
            <Book />
            <br />
            <TimelineWonk />
          </div>
          <div className="column">
            <BookFireBorne />
            <br />
            <AACert2020 />
            <br />
            <LasVegasFinalist />
            <PlayDorset18 />
            <br />
            <PlayTWill17 />
            {/* <PhoenixBooks /> */}
          </div>
          <div className="column">
            <MoralicAcid />
            <br />
            <TwfestOneAct />
            <br />
            <BookKite />
            <br />
            <Documents />
            <br />
            <Hartman />
            <br />
            {/* <ClinicPhases /> */}
            <BookAAuth />
          </div>
        </div>
      </div>

      <div className="section" style={{ backgroundColor: "#fff9ea" }}>
        <div className="container">
          <h1 className="title has-text-centered has-text-primary">
            Radio Spots
          </h1>
          <p className="subtitle has-text-centered">
            <em>Listen to some award winning radio comedy advertising!</em>
          </p>
          <div className="columns">
            <div className="column">
              <Audio711 />
            </div>
            <div className="column">
              <AudioBalloon />
            </div>
            <div className="column">
              <AudioCabells />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HomePage;
