import React from "react";

const ContactBadges = () => (
  <>
    <nav className="level">
      <div className="level-item has-text-centered">
        <a
          href="https://leagueofvermontwriters.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="./static/img/lvw-league-logo.jpg"
            alt="League of Vermont Writers"
            title={"League of Vermont Writers"}
            style={{ height: "64px" }}
          />
        </a>
      </div>
    </nav>
    <nav className="level">
      <div className="level-item has-text-centered">
        <a
          href="https://www.namw.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="./static/img/namw-member-badge.jpg"
            alt="National Association of Memoir Writers"
            title={"National Association of Memoir Writers"}
            style={{ height: "64px" }}
          />
        </a>
      </div>
    </nav>
  </>
);
export default ContactBadges;
