import React from "react";

const TimelineWonk = () => (
  <>
    <div className="card">
      <div className="card-image">
        <figure className="image is-3by2">
          <img
            src="./static/img/01-timeline-wonks.jpg"
            alt="Timeline Wonk blog"
          />
        </figure>
        <hr />
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">Blog: Timeline Wonk</p>
          </div>
        </div>
        <div className="content">
          <nav className="level">
            <div className="level-item has-text-centered">
              <a
                className="button is-info"
                href="https://timelinewonk.blogspot.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <i className="mdi mdi-link-variant"></i>
                </span>
                <span>Read More</span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </>
);

export default TimelineWonk;
