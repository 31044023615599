import React from "react";

const LasVegasFinalist = () => {
  let title = "2019 Las Vegas International Film & Screenwiting Competition";
  return (
    <div className="box" style={{ borderRadius: 0 }}>
      <div className="media">
        <div className="media-left">
          <figure className="image is-128x128">
            <img src="./static/img/2019-las-vegas-finalist.png" alt={title} />
          </figure>
        </div>
        <article className="media-content">
          <div className="content has-text-centered">
            <p>
              {/* <small>{title}</small><br/> */}
              <strong>2019 - FINALIST</strong>
            </p>
            <p>
              <strong>“How Santa Claus Came To Simpson’s Bar”</strong>
            </p>
            <p>Feature Length Filmscript – Western Category</p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default LasVegasFinalist;
