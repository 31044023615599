import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from '../pages/Home';
import Kudos from '../pages/Kudos';
import Works from '../pages/Works';

export default (
  <Router>
    <Switch>
      <Route path='/' exact component={HomePage} />
      <Route path='/kudos' exact component={Kudos} />
      <Route path='/works' exact component={Works} />
    </Switch>
  </Router>
);
