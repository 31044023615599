import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Kudos = () => (
  <>
    <Navbar></Navbar>
    <section className="section">
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="content">
            <h4>DANTE IN JIFFY LUBE</h4>
            <p>1-act stage version</p>
            <blockquote>
              Congratulations! Your play has been selected out of 140 entrants,
              to be part of Theatre One's Slice of Life Festival of New Ten
              Minute plays. The performances will be March 22,23 and 24 at the
              Alley Theatre 133 Center St Middleboro, MA. Five different plays
              will be read at each performance and the audience will respond to
              a questionaire pertaining to your play that will be forwarded to
              you, in hopes that it will encourage and help guide you as you
              countinue your journey as a playwright. <br />
              <br />
              Sincerely <br />
              Peg Holzemer <br />
              Producing Artistic Director Theatre One Productions
            </blockquote>
            <hr />
            <h4>HOW SANTA CLAUS CAME TO WHISKY-ALPHA-ROMEO</h4>
            <p>
              10:00 stage version <br />
              THE COLLECTIVE NY
            </p>
            <blockquote>
              Dear Jean,
              <br />
              Thank you for submitting your play to The Collective NY's first
              Collective10 Ten-Minute Play Festival: 13 For 2013! We received
              over 300 scripts in a blind submission process, and spent
              countless hours debating and advocating in the final weeks. Your
              play was read by a committee of 8 individuals, read aloud to our
              Permanent Ensemble during our Collective Monday workshop to high
              praise, and was ultimately chosen as a Top 30 Finalist out of the
              300+ plays submitted. Selecting from that pool of exceptional
              talent was a difficult task tempered by the considerations of a
              festival program. Ultimately, our decisions came down to creating
              a balanced evening of theatre, and sadly we will not have a place
              for your play in this inaugural fest, but know how impressed we
              were with your script and that we hope to create an opportunity to
              work together in the future.
            </blockquote>
            <hr />
            <h4>A-WIRE</h4>
            <p>One act stage version</p>
            <blockquote>
              Mr. Yeager:
              <br />
              "A-Wire" was one of the twelve plays that advanced to the
              semifinals of our annual contest! Unfortunately, it was not one of
              the five finalists which will be given a staged reading this year.
              But the judges were so impressed with the work that with your
              permission we will submit it again in next year's competition,
              with no need for an additional processing fee. Just let us know if
              this meets with your approval.
              <br />
              <br />
              Congratulations!
              <br />
              Jesse Waldinger <br />
              Chair, Play Selection Committee <br />
              HRC Showcase Theatre
            </blockquote>
            <hr />
            <h4>SUBSTANCE OF LOVE</h4>
            <p>One Act stage version</p>
            <blockquote>
              Greetings Playwrights & 1st Panel Readers:
              <br />
              We send many thanks to the 21 readers who just finished reading
              the 7th Annual Nor'Eastern Playwriting Contest. Thanks to the 52
              playwrights from all over the Northeast who made this such an
              exciting first round to read.
              <br />
              The following plays have advanced to the 2nd panel. These 14 plays
              have moved on to the second level. From these plays 7 will advance
              to the final reader and 3 will be selected to workshop and
              showcase on May 9, 10 & 11, 2013. Continuing playwrights, please
              keep those dates open for us.
              <br />
              <br />
              Best wishes to all.
              <br />
              Peter Marshbr <br />
              Sandra Gartner <br />
              Vermont Actors' Repertory Theatre <br />
              Producing Directors
            </blockquote>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </>
);
export default Kudos;
